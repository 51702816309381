import { TextResizerValue } from "./types";
import {
  textResizerDefaultValue,
  textResizerMinValue,
  textResizerMaxValue,
} from "./constants";

export const parseTextResizerValue = (input?: string | number | null): TextResizerValue => {
  const value = Number(input);

  if (isNaN(value)) {
    return textResizerDefaultValue;
  }

  return Math.max(
    textResizerMinValue,
    Math.min(value, textResizerMaxValue),
  ) as TextResizerValue;
};
