import * as AssessmentComponents from '@openstax/assessment-components';
import React from 'react';
import {
  FormattedAnswerState,
  FormattedAnswerFeedback
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/utils/xapiUtils";
import {
  Exercise
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/types";
import { assertDefined } from "@openstax/ts-utils/assertions";

type QuestionWithState = Exercise['questions'][number] & {answer_state: FormattedAnswerState};
type ExerciseWithState = Omit<Exercise, 'questions'> & {questions: QuestionWithState[]};

type FormattedAssessmentState = {exercises: Array<ExerciseWithState>};

type QuestionStateSummary = Pick<
  AssessmentComponents.ExerciseWithQuestionStatesProps['questionStates'][number], 'is_completed'
> & {
  exercise_uuid: string;
  score?: FormattedAnswerState['score'];
  id: number;
};
type QuestionStateDetail = AssessmentComponents.ExerciseWithQuestionStatesProps['questionStates'][number] & {
  exercise_uuid: string;
  score?: FormattedAnswerState['score'];
  id: number;
};
export type BackendQuestionSummaryState = {
  id: number;
  exercise_uuid: string;
  answer_state: FormattedAnswerState;
};
export type BackendQuestionFeedbackState = {
  id: number;
  exercise_uuid: string;
  answer_state: FormattedAnswerState & FormattedAnswerFeedback;
};

export type QuestionState = QuestionStateSummary | QuestionStateDetail;

// TODO - support partial credit state, unknown credit "completed" state, "incomplete" state
export const getCorrectnessVariant = (
  question: QuestionState,
  feedbackEnabled: boolean
): AssessmentComponents.ProgressBarItemVariant => {
  return question.is_completed
    ? question.score?.scaled !== undefined && feedbackEnabled
      ? question.score?.scaled >= 1
        ? 'isCorrect'
        : question.score?.scaled <= 0
          ? 'isIncorrect'
          : 'isIncorrect' // TODO - partial credit state
      : null // TODO completed, no score available state
    : 'isIncomplete';
};

const mapAnswerStateSummaryFieldNames = (backendState: BackendQuestionSummaryState) => ({
  is_completed: backendState.answer_state.is_complete,
  attempts_remaining: 2,
  attempt_number: 0,


  id: backendState.id,
  score: backendState.answer_state.score,
  exercise_uuid: backendState.exercise_uuid,
  canAnswer: !backendState.answer_state.is_complete,
});

const mapAnswerStateDetailFieldNames = (backendState: BackendQuestionFeedbackState) => ({
  ...mapAnswerStateSummaryFieldNames(backendState),

  // default values, override dirty state
  apiIsPending: false,
  needsSaved: !backendState.answer_state.is_complete,

  // these are for features we haven't built yet
  free_response: '',
  answer_id_order: [],

  // mapping backend state to frontend state format
  // TODO - try to make changes to minimize the logic here
  available_points: backendState.answer_state.score.max
    ? backendState.answer_state.score.max.toFixed(1) as `${number}.${number}`
    : '1.0' // TODO - support not having points in the library
  ,
  answer_id: backendState.answer_state.score.scaled === undefined ? backendState.answer_state.response_id : undefined,
  correct_answer_id: (
    backendState.answer_state.score.scaled !== undefined && backendState.answer_state.score.scaled >= 1
      ? backendState.answer_state.response_id // if the answer was correct, the response_id is the correct answer
      : backendState.answer_state.correct_answer_id // otherwise look for a given `correct_answer_id`
  ) || '', // default state for unanswered or if the assessment doesn't allow revealing the correct answer
  correct_answer_feedback_html: (
    backendState.answer_state.score.scaled !== undefined && backendState.answer_state.score.scaled >= 1
      ? backendState.answer_state.answer_level_feedback // if the answer was correct, the selected answer is correct
      : backendState.answer_state.correct_answer_feedback // otherwise look for given `correct_answer_feedback`
  ) || '', // default state for unanswered or if the assessment doesn't allow feedback
  attempts_remaining: backendState.answer_state.attempts_remaining,
  // the frontend considers the first attempt to be attempt number 0, backend calls it 1
  attempt_number: (backendState.answer_state.completed_attempts - 1) + (backendState.answer_state.is_complete ? 0 : 1),
  incorrectAnswerId: backendState.answer_state.score.scaled !== undefined && backendState.answer_state.score.scaled < 1
    ? backendState.answer_state.response_id || '' : '',
  // this prop name in the UI is misleading, its really for the incorrect answer feedback
  feedback_html:  backendState.answer_state.score.scaled !== undefined && backendState.answer_state.score.scaled < 1
    ? backendState.answer_state.answer_level_feedback || '' : '',
  // not sure if this whole structure is really providing any value, the BE strips it to just the html
  solution: backendState.answer_state.question_level_feedback ? {
    images: [],
    solution_type: "detailed",
    content_html: backendState.answer_state.question_level_feedback
  } : undefined,
});

export const useQuestionState = (assessment: FormattedAssessmentState) => {
  const [hash, setHash] = React.useState<{[key: string]: QuestionStateDetail}>({});

  const list = React.useMemo(() => assessment.exercises.reduce(
    (result, exercise) => ([
      ...result,
      ...exercise.questions.reduce(
        (exerciseQuestions, question) => ([
          ...exerciseQuestions,
          hash[question.id] || mapAnswerStateSummaryFieldNames({
            ...question, exercise_uuid: exercise.uuid, id: question.id
          })
        ])
      , [] as QuestionState[])
    ])
  , [] as QuestionState[]), [hash, assessment]);

  const updateQuestionState = React.useCallback((state: BackendQuestionFeedbackState) => {
    setHash(previous => ({...previous, [state.id.toString()]: mapAnswerStateDetailFieldNames(state)}));
  }, [setHash]);

  const setPendingResponse = React.useCallback((state: {question_id: number; id: number}) => {
    setHash(previous => ({...previous, [assertDefined(state.question_id, 'must have question').toString()]: {
      ...previous[assertDefined(state.question_id, 'must have question').toString()],
      answer_id: state.id,
      needsSaved: true,
    }}));
  }, [setHash]);

  const setApiPending = React.useCallback((question_id: string, value = true) => {
    setHash(previous => ({...previous, [question_id]: {
      ...previous[question_id],
      apiIsPending: value,
    }}));
  }, [setHash]);

  const firstQuestionNumByExercise = React.useMemo(() => assessment.exercises.reduce(
    (result, exercise) => ({
      ...result,
      [exercise.uuid]: result.questionCounter + 1,
      questionCounter: result.questionCounter + exercise.questions.length
    }), {questionCounter: 0} as {questionCounter: number; [key: string]: number}), [assessment]);

  return [hash, list, firstQuestionNumByExercise, updateQuestionState, setPendingResponse, setApiPending] as const;
};
