import React from "react";
import { TextResizerValue } from "../types";

const textResizerValues: TextResizerValue[] = [-2, -1, 0, 1, 2, 3];

export const useTextResizeIntegration = (handleChange: (value: TextResizerValue) => void) => {
  React.useEffect(() => {
    if (typeof window === 'undefined' || !window.parent || window.parent === window) {
      return;
    }

    const win = window;
    const referrer = new URL(win.document.referrer);

    const handler = (event: MessageEvent) => {
      if (
        event.data.type === 'TextSizeUpdate' &&
        event.origin === referrer.origin &&
        textResizerValues.includes(event.data.value as TextResizerValue)
      ) {
        handleChange(event.data.value);
      }
    };

    win.addEventListener('message', handler);
    return () => win.removeEventListener('message', handler);
  }, [handleChange]);
};
