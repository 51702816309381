import React from 'react';
import * as AssessmentComponents from '@openstax/assessment-components';
import { assertString } from '@openstax/ts-utils/assertions';
import { createRoute, makeScreen } from "../../../core/services";
import { useApiClient } from "../../api";
import {
  FetchState,
  fetchLoading,
  fetchSuccess,
  FetchStateType,
  stateHasData,
} from "@openstax/ts-utils/fetch";
import {
  assessmentSubmissionReviewTemplate,
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/integration.config";
import type {
  SubmissionAssessmentResponse,
  SubmissionExerciseResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/review";
import { useServices } from "../../../core/context/services";
import { Exercise } from '../../components/Exercise';
import { useSetAppError } from '@openstax/ui-components';
import {getCorrectnessVariant, useQuestionState} from '../../utils/exercises';

const useExerciseStates = (id: string) => {
  const apiClient = useApiClient();
  const setAppError = useSetAppError();
  const [state, setState] = React.useState<FetchState<SubmissionAssessmentResponse, string>>(fetchLoading());
  const launchToken = assertString(useServices().launchToken, new Error('missing JWT token'));

  React.useEffect(() => {
    apiClient.apiV0GetSubmissionAssessment({params: {id}, query: {t: launchToken}})
      .then((response) => response.acceptStatus(200))
      .then((response) => response.load())
      .then((response) => { setState(fetchSuccess(response)); })
      .catch(setAppError);
  }, [apiClient, setAppError, id, launchToken]);

  return state;
};

const useExercise = (assessmentId: string, exerciseUuid: string) => {
  const apiClient = useApiClient();
  const setAppError = useSetAppError();
  const [state, setState] = React.useState<FetchState<SubmissionExerciseResponse, string>>(fetchLoading());
  const launchToken = assertString(useServices().launchToken, new Error('missing JWT token'));

  React.useEffect(() => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0GetSubmissionExercise({params: {assessmentId, exerciseUuid}, query: {t: launchToken}})
      .then((response) => response.acceptStatus(200))
      .then((response) => response.load())
      .then((response) => { setState(fetchSuccess(response)); })
      .catch(setAppError);
  }, [apiClient, setAppError, assessmentId, exerciseUuid, launchToken]);

  return state;
};

const SubmissionReview = ({assessmentId, submission}: {
  assessmentId: string;
  submission: SubmissionAssessmentResponse;
}) => {
  const [questionStateHash, list, firstQuestionNumByExercise, updateQuestionState] = useQuestionState(submission);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const exerciseState = useExercise(assessmentId, list[currentQuestionIndex].exercise_uuid);

  React.useEffect(() => {
    if (stateHasData(exerciseState) && exerciseState.type === FetchStateType.SUCCESS) {
      exerciseState.data.questions.forEach(question => updateQuestionState({
        id: question.id,
        exercise_uuid: exerciseState.data.uuid,
        answer_state: {
          ...question.answer_state,
          // pretend its complete to show the next button
          is_complete: true,
          // UI is confusing on un-submitted questions if the correct
          // answer is highlighted, so show them without it
          ...(question.answer_state.completed_attempts < 1
            ? {correct_answer_id: undefined}
            : {}
          )
        }
      }));
    }
  }, [exerciseState, updateQuestionState]);

  return <>
    <AssessmentComponents.ProgressBar
      activeIndex={currentQuestionIndex}
      goToStep={setCurrentQuestionIndex}
      steps={
        list.map(
          (question) => ({
            variant: getCorrectnessVariant(question, true)
          })
        )
      }
    />
    {exerciseState.type === FetchStateType.LOADING
      ? <AssessmentComponents.Loader />
      : null
    }
    {exerciseState.type === FetchStateType.SUCCESS ? <Exercise
      hasFeedback={false}
      hasMultipleAttempts={false}
      data={exerciseState.data}
      answerState={questionStateHash}
      onNextStep={(index: number) => {
        setCurrentQuestionIndex(Math.min(index + 1, list.length - 1));
      }}
      index={currentQuestionIndex}
      savePendingResponse={() => undefined}
      setPendingResponse={() => undefined}
      numberOfQuestions={submission.exercises.length}
      firstQuestionNumber={firstQuestionNumByExercise[exerciseState.data.uuid]}
      assessmentId={assessmentId}
    /> : null}
  </>;
};

export const SubmissionReviewComponent = (params: {id: string}) => {
  const exerciseStates = useExerciseStates(params.id);

  if (stateHasData(exerciseStates)) {
    return <SubmissionReview assessmentId={params.id} submission={exerciseStates.data} />;
  }

  return null;
};

export const reviewAssessmentScreen = createRoute({
  name: 'reviewAssessmentScreen',
  path: assessmentSubmissionReviewTemplate,
  handler: makeScreen(SubmissionReviewComponent),
});
